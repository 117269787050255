import * as React from "react"
import Home from '../pages/home'

const IndexPage = () => {
  return (
    <main>
      <Home />
    </main>
  )
}

export default IndexPage
